export const Styles = {
  label: "block mb-2 text-sm font-medium text-gray-900",
  input:
    "shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500 shadow-sm-light",
  box: "relative z-0 w-full mb-6 group",
  btnBox: "flex w-full justify-center items-center",
  btn: "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xl px-8 py-4 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800",
  formBox: "grid gap-4 mb-4 sm:grid-cols-2 p-5",
  formBoxTime:
    "grid gap-4 mb-4 lg:grid-cols-7 md:grid-cols-6 sm:grid-cols-4 xs:grid-cols-2  p-5",
  radioBtn:
    "w-full py-3 ml-2 text-sm font-medium text-gray-900 text-gray-300",
  radioInput:
    "w-8 h-8 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-blue-600 ring-offset-gray-700 focus:ring-offset-gray-700 focus:ring-2 bg-gray-600 border-gray-500",
  li: "w-full border-gray-600",
  title: "ml-5 py-5 text-3xl font-bold text-gray-900 mb-2",
  liR: "w-full border-b border-gray-200 sm:border-b-0 sm:border-r border-gray-600",
  ul: "items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex bg-gray-700 border-gray-600 text-white",
  select:
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500",
    timeSlot:
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-300 p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500",
  
  
};