import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/fitness-logo-1.png";
import { Styles } from "../components/Styles";

function LoginScreen() {
  const navigation = useNavigate();
  const [userName, setuserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (userName === "" && password === "") {
      setErrorMessage("Please enter your username and password ");
    } else if (userName === "") {
      setErrorMessage("Please enter your username ");
    } else if (password === "") {
      setErrorMessage("Please enter your password ");
    } else if (password.length < 6) {
      setErrorMessage(
        "Password must be at least 6 characters and contain at least one number"
      );
    } else if (userName === "admin" && password === "admin123") {
      setIsLoggedIn(true);
    } else {
      setErrorMessage("Invalid username or password");
    }
  };

  if (isLoggedIn) {
    localStorage.setItem('login',true)
    return navigation("/form");
  }

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          className="block mx-auto mt-7"
          style={{
            height: 300,
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </div>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <text
          style={{
            fontSize: 35,
            fontWeight: "bold",
            color: "white",
            textAlign: "center",
          }}
        >
          Login
        </text>
        <br />
        <br />
        <form onSubmit={handleSubmit}>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input
              className={Styles.input}
              style={{ width: "250px" }}
              value={userName}
              placeholder="Username"
              onChange={(event) => setuserName(event.target.value)}
            />

            <input
              className={Styles.input}
              style={{ width: "250px", marginTop: "2rem" }}
              value={password}
              placeholder="Password"
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <br />
          <br />
          {errorMessage && (
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                marginBottom: "30px",
              }}
            >
              <p
                style={{
                  color: "red",
                }}
              >
                {errorMessage}
              </p>{" "}
            </div>
          )}
          <button
            className="bg-[#95396A] rounded h-9 w-24 text-white"
            type="submit"
          >
            Log In
          </button>
          <br />
          <br />
          <br />
        </form>
      </div>
    </div>
  );
}

export default LoginScreen;
