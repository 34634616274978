import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Form from "./Form";
import LoginScreen from "./components/LoginScreen";
import PrivateRoute from "./components/PrivateRoute";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <PrivateRoute Component={LoginScreen} />,
    },
    {
      path: "/form",
      element: <PrivateRoute Component={Form} />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
