import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function PrivateRoute(props) {
  const {Component} = props;
  const navigation = useNavigate();
  useEffect(()=>{
    const logined = localStorage.getItem('login')
    if(!logined){
      navigation("/");
    }
  },[])
  return <Component />;
}

export default PrivateRoute;
