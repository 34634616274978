import React, { useState } from "react";
import EnquiryForm from "./components/EnquiryForm";
import RegistrationForm from "./components/RegistrationForm";
import logo from "../src/assets/fitness-logo-1.png";
import Search from "./components/Search";
import { useNavigate } from "react-router-dom";

const Form = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(1);
  const handleSignOut = () => {
    localStorage.clear();
    navigate("/")
}

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  
  };
  return (
    <div className="relative p-4 bg-white-800 min-h-screen shadow sm:p-5">
    <div className="mx-auto max-w-5xl  p-4 bg-white-800 rounded-lg shadow dark:bg-white-800 sm:p-5">
      <div style={{alignItems: 'center', justifyContent: "flex-end", display: "flex"}} >
      <button className="bg-[#95396A] rounded h-9 w-24 text-white right-0" onClick={handleSignOut} title="Log Out">
        <text style={{textAlign: "center"}}>Log Out</text>
        </button>
      </div>
    <div
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          className="block mx-auto"
          style={{
            height: 200,
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </div>
      <div className="flex flex-col justify-center items-center">
        <h1 className="mb-4 text-2xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white bg-gradient-to-r from-[#e10220] via-[#f06629] to-[#fec533] ...  " style={{WebkitBackgroundClip:'text',color:"transparent"}} >
          Welcome to Fitness 365&deg;
        </h1>
        <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
        Fit forever, young forever
        </p>
      </div>
      <ul className="flex flex-wrap text-xl font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
        <li className="mr-2">
          <button
            className={
              activeTab === 1
                ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"
                : "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
            }
            onClick={() => handleTabClick(1)}
            aria-current="page"
          >
            Enquiry
          </button>
        </li>
        <li className="mr-2">
          <button
            className={
              activeTab === 2
                ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"
                : "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
            }
            onClick={() => handleTabClick(2)}
          >
            Registration
          </button>
        </li>
        <li className="mr-2">
          <button
            className={
              activeTab === 3
                ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"
                : "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
            }
            onClick={() => handleTabClick(3)}
          >
           Profile
          </button>
        </li>
      </ul>

      <div className="tab-content">
        {activeTab === 1 && <EnquiryForm />}
        {activeTab === 2 && <RegistrationForm />}
        {activeTab === 3 && <Search />}
      </div>
    </div>
    </div>
  );
};

export default Form;
