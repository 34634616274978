import React from 'react'

function HeaderLine() {
  return (
    <div>
      <div 
      className='bg-gradient-to-r from-[#e10220] via-[#f06629] to-[#fec533] ... h-1 w-24 -mt-5'></div>
    </div>
  )
}

export default HeaderLine
