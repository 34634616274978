
export function generateCustomId(firstName, dob, phone) {
  // Extract first three characters of first name
  const firstThreeChars = firstName.toUpperCase().slice(0, 3);

  // Extract day and month from DOB
  const dayMonth = dob.slice(8, 10) + dob.slice(5, 7);

  // Extract last four digits of phone number
  const lastFourDigits = phone.slice(-3);

  // Combine all the extracted values to form the custom ID
  const customId = firstThreeChars + dayMonth + lastFourDigits;

  return customId;
}

 
