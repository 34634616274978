import React, { useState } from "react";
import { Styles } from "../components/Styles";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { generateCustomId } from "./utils/idGenerator";
const initialEnquiryState = {
  user_id:"",
  first_name: "",
  last_name: "",
  gender: "male",
  mob_number: "",
  email_address: "",
  address: "",
  dob: "",
  
};


const EnquiryForm = () => {
  const [loading, setLoading] = useState(false);
  const [enquiryData, setEnquiryData] = useState(initialEnquiryState);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    gender: "",
    address: "",
    dob: "",
  });
  const url =
    "https://fitness365degree-backend-aayc.vercel.app/enquiry";
  const handleEnquirySubmit = async () => {
    setLoading(true);
    try {
      if (!isValid()) {
        return setLoading(false);
      }
     
      const { first_name, dob, mob_number } = enquiryData;
      const customId = generateCustomId(first_name, dob, mob_number);
      enquiryData.user_id=customId;
      console.log(customId)
      const response = await fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(enquiryData),
      });

      const responseData = await response.json();

      console.log("Enquiry submitted successfully:", responseData);
      setLoading(false);
      toast.success('🦄 Enquiry added successfully!', {
        position: 'top-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      })

      // Clear the form data after submission
      setEnquiryData(initialEnquiryState);
    } catch (error) {
      console.error("Error submitting enquiry:", error);
      setLoading(false);
    }
   
  };
  const handleEnquiryChange = (event) => {
    const { name, value } = event.target;

    setEnquiryData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const isValid = () => {
    const {first_name,last_name,gender,mob_number,email_address,address,dob}=enquiryData;
    let valid = true;
   const errobj = {}
    console.log(enquiryData)
    // Validate first name
    if(!first_name){
      errobj.firstName="First name required"
      valid = false;
    }

    // Validate last name
    if(!last_name){
      errobj.lastName="Last name required"
      valid = false;
    }
    // Validate email
    if(!email_address){
      errobj.email="Email required"
      valid = false;
    }
    const emailRegex = /\S+@\S+\.\S+/;
    if (!!email_address && !emailRegex.test(email_address)) {
      errobj.email="Invalid email"
      valid = false;
    }

    // Validate mobile number
    if(!mob_number){
      errobj.mobileNumber="Mobile Number required"
      valid = false;
    }

    const mobileRegex = /^\d{10}$/;
    if (!mobileRegex.test(mob_number)) {
      
      errobj.mobileNumber="Invaild Mobile Number"
      valid = false;
    }

    // Validate gender
    // if(gender){
    //   errobj.gender="Gender required"
    //   valid = false;
    // }
    // if (["male", "female", "other"].includes(gender)) {
     
    //   errobj.gender="Invalid gender"
    //   valid = false;
    // }

    // Validate address
    if(!address){
      errobj.address="Address required"
      valid = false;
    }
    
    if(!dob){  
      errobj.dob="Date of birth required"
      valid = false;
    }
       
   
    setErrors(errobj)
    return valid;
  };

  return (
    <form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className={Styles.formBox}>
        <div className={Styles.box}>
          <label className={Styles.label}>First Name (*):</label>
          <input
            className={Styles.input}
            type="text"
            name="first_name"
            value={enquiryData.first_name}
            onChange={handleEnquiryChange}
          />
          {errors.firstName && <div><p style={{color:'#e35b52',fontSize:12}}>{errors.firstName }</p></div>}
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Last Name (*):</label>
          <input
            className={Styles.input}
            type="text"
            name="last_name"
            value={enquiryData.last_name}
            onChange={handleEnquiryChange}
          />
          {errors.lastName && <div><p style={{color:'#e35b52',fontSize:12}}>{errors.lastName }</p></div>}
        </div>

        {/* <div className={Styles.box}>
          <label className={Styles.label}>Gender (*):</label>
          <input
            className={Styles.input}
            type="text"

            name="gender"
            defaultValue={enquiryData.gender}
            onChange={handleEnquiryChange}
          />
           {errors.gender && <div><p style={{color:'#e35b52',fontSize:12}}>{errors.gender }</p></div>}

        </div> */}
        <div className={Styles.box}>
          <label className={Styles.label}>Gender:</label>{" "}
          <select
            className={Styles.select}
            name="gender"
            value={enquiryData.gender}
            onChange={handleEnquiryChange}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
            
          </select>
          {errors.gender && <div><p style={{color:'#e35b52',fontSize:12}}>{errors.gender }</p></div>}
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Mobile Number (*):</label>
          <input
            className={Styles.input}
            type="tel"
            name="mob_number"
            value={enquiryData.mob_number}
            onChange={handleEnquiryChange}
          />
           {errors.mobileNumber && <div><p style={{color:'#e35b52',fontSize:12}}>{errors.mobileNumber }</p></div>}
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Email Address (*):</label>
          <input
            className={Styles.input}
            type="email"
            name="email_address"
            value={enquiryData.email_address}
            onChange={handleEnquiryChange}
          />
           {errors.email && <div><p style={{color:'#e35b52',fontSize:12}}>{errors.email}</p></div>}
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}> Address (*):</label>
          <input
            className={Styles.input}
            type="text"
            name="address"
            value={enquiryData.address}
            onChange={handleEnquiryChange}
          />
          {errors.address && <div><p style={{color:'#e35b52',fontSize:12}}>{errors.address}</p></div>}
        </div>
        {/* <div className={Styles.box}>
          <label className={Styles.label}>Date of Birth (*):</label>
          <input
            className={Styles.input}
            type="text"
            name="dob"
            value={enquiryData.dob}
            onChange={handleEnquiryChange}
            placeholder="MM/DD/YYYY"
          />
           {errors.dob && <div><p style={{color:'#e35b52',fontSize:12}}>{errors.dob}</p></div>}
        </div> */}
        <div className={Styles.box}>
          <label className={Styles.label}>Date of Birth:</label>{" "}
          <input
            className={Styles.input}
            type="date"
            name="dob"
            max="2023-05-06"
            value={enquiryData.dob}
            onChange={handleEnquiryChange}
          />
          {errors.dob && <div><p style={{color:'#e35b52',fontSize:12}}>{errors.dob}</p></div>}
        </div>
      </div>
      <div className={Styles.btnBox}>
        {loading ? (
          <button
            disabled
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
          >
            <svg
              aria-hidden="true"
              role="status"
              className="inline w-4 h-4 mr-3 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
            Loading...
          </button>
        ) : (
          <button
            type="button"
            disabled={loading}
            onClick={handleEnquirySubmit}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
          >
            Submit Enquiry
          </button>
        )}
      </div>
    </form>
  );
};

export default EnquiryForm;
