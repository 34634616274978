import React, { useEffect } from "react";
import { useState } from "react";
import { Styles } from "../components/Styles";
import ContentEditable from "react-contenteditable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Search() {
  const [userID, setUserID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userData, setUserData] = useState(null); // State to hold the user data
  const [loading, setLoading] = useState(false);

  const handleSearch = async (event) => {
    event.preventDefault();
    if (!userID) {
      setErrorMessage("Enter user ID");
      return;
    }
    try {
      const response = await fetch(`https://fitness365degree-backend-aayc.vercel.app/user/${userID}`);
      if (response.ok) {
        const data = await response.json();
        setUserData(data);
        setErrorMessage(null)
      } else {
        throw new Error("User not found");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("No user with such id ");
    }
  };
  

  const handleCellChange = (event, index) => {
    const updatedData = [...userData];
    updatedData[index] = event.target.value;
    setUserData(updatedData);
    console.log(userData);
  };
  const handleUpdateUser = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://fitness365degree-backend-aayc.vercel.app/user/${userID}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        toast.success("🦄Updated successfully!", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setUserData(null)
        setErrorMessage(null)
      } else {
      }
    } catch (error) {
      console.error(error);
      // Handle error here (e.g., show error message)
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div>
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="mt-20">
        <form onSubmit={handleSearch}>
          <label className="my-5 mt-5" style={{ marginTop: "10px" }}>
            User ID
          </label>
          <div className="bg-gradient-to-r from-[#e10220] via-[#f06629] to-[#fec533] ... h-1 w-24  "></div>
          <input
            className={Styles.input}
            style={{ width: "25%", marginTop: "20px" }}
            type="text"
            name="userID"
            placeholder="Enter user ID"
            value={userID}
            onChange={(event) => {
              setUserID(event.target.value);
            }}
          />
          {errorMessage && (
            <div>
              <p
                style={{
                  color: "red",
                }}
              >
                {errorMessage}
              </p>{" "}
            </div>
          )}
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center mt-5"
          >
            Search
          </button>
        </form>
      </div>
      <div>
          {userData!==null ? (
           <div> 
        <div className="relative  my-10 overflow-x-auto shadow-md sm:rounded-lg">
           <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th className="px-6 py-4" scope="col">
                    ID
                  </th>
                  <th className="px-6 py-4" scope="col">
                    First Name
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Last Name
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Gender
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Contact No.
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Email
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Address
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Program
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Plan
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Amount Paid
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Amount Due
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Due Date
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Date
                  </th>
                  <th className="px-6 py-4" scope="col">
                    DOB
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Bodyweight
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Weight
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Chest
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Inhale
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Exhale
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Upper Abs
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Lower Abs
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Middle Abs
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Waist
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Hips
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Upper Thigh
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Lower Thigh
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Calves
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Neck
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Shoulder
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Timeslot
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Height
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Medical History
                  </th>
                  <th className="px-6 py-4" scope="col">
                    Food Preference
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  {userData?.map((value, index) => (
                    <td key={index}>
                      <ContentEditable
                        html={value}
                        contentEditable="true"
                        onChange={(event) => handleCellChange(event, index)}
                        className="px-6 py-4"
                      />
                    </td>
                  ))}
                </tr>
              </tbody>
             
            </table>
        </div>
        <div className={Styles.btnBox}>
                {loading ? (
                  <button
                    disabled
                    type="button"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                  >
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 mr-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="button"
                    disabled={loading}
                    onClick={handleUpdateUser}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                  >
                    Submit Registration
                  </button>
                )}
              </div>
           </div>

          ) : (
         <></>
          )}
      </div>
    </div>
  );
}

export default Search;
