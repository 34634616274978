import React, { useState, useEffect } from "react";
import { Styles } from "../components/Styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { generateCustomId } from "./utils/idGenerator";
import HeaderLine from "./HeaderLine";

export const morning = [
  { time: "06 to 07 AM", isSelected: false, id: 0 },
  { time: "07 to 08 AM", isSelected: false, id: 1 },
  { time: "08 to 09 AM", isSelected: false, id: 2 },
  { time: "09 to 10 AM", isSelected: false, id: 3 },
  { time: "10 to 11 AM", isSelected: false, id: 4 },
];
export const evening = [
  { time: "04 to 05 PM", isSelected: false, id: 0 },
  { time: "05 to 06 PM", isSelected: false, id: 1 },
  { time: "06 to 07 PM", isSelected: false, id: 2 },
  { time: "07 to 08 PM", isSelected: false, id: 3 },
  { time: "08 to 09 PM", isSelected: false, id: 4 },
  { time: "09 to 10 PM", isSelected: false, id: 5 },
  { time: "10 to 11 PM", isSelected: false, id: 6 },
];
const inputDate = new Date();
const options = { year: "numeric", month: "long", day: "numeric" };
const formattedDate = inputDate.toLocaleDateString("en-US", options);

console.log(formattedDate);

const RegistrationForm = () => {
  const [loading, setLoading] = useState(false);
  // const [timeData, setTimeData] = useState({
  //   morning: [...morning],
  //   evening: [...evening],
  //   selectedSlot: null,
  // });

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const initialRegistrationState = {
    first_name: null,
    last_name: null,
    gender: "male",
    timeslot: " ",
    mob_number: " ",
    email_address: " ",
    address: null,
    program: " ",
    plan: " ",
    amount_paid: 0,
    amount_due: 0,
    date_time: formattedDate,
    amount_due_date: " ",
    timeSlot: " ",
    dob: null,
    bodyweight: " ",
    weight: " ",
    chest: " ",
    inhale: " ",
    exhale: " ",
    upper_abs: " ",
    lower_abs: " ",
    middle_abs: " ",
    waist: " ",
    hips: " ",
    upper_thigh: " ",
    lower_thigh: " ",
    calves: " ",
    neck: " ",
    shoulder: " ",
    height: " ",
    medicalHistory: " ",
    foodPreference: " ",
  };
  const [registrationData, setRegistrationData] = useState(
    initialRegistrationState
  );

  const [errors, setErrors] = useState({
    firstName: " ",
    lastName: " ",
    email: " ",
    mobileNumber: " ",
    gender: " ",
    address: " ",
    dob: " ",
    amountPaid: " ",
    plan: " ",
    program: " ",
  });

  const isValid = () => {
    const {
      first_name,
      last_name,
      mob_number,
      email_address,
      address,
      dob,
      amount_paid,
      plan,
      program,
    } = registrationData;
    console.log(program)
    let valid = true;
    const errobj = {};
    console.log(registrationData);
    // Validate first name
    if (!first_name) {
      errobj.firstName = "First name required";
      valid = false;
    }

    // Validate last name
    debugger
    if (!last_name) {
     
      errobj.lastName = "Last name required";
      valid = false;
    }
    // Validate email
    if (!email_address) {
      errobj.email = "Email required";
      valid = false;
    }
    const emailRegex = /\S+@\S+\.\S+/;
    if (!!email_address && !emailRegex.test(email_address)) {
      errobj.email = "Invalid email";
      valid = false;
    }

    // Validate mobile number
    if (!mob_number) {
      errobj.mobileNumber = "Mobile Number required";
      valid = false;
    }

    const mobileRegex = /^\d{10}$/;
    if (!mobileRegex.test(mob_number)) {
      errobj.mobileNumber = "Invaild Mobile Number";
      valid = false;
    }

    // validate Plan
    if (plan === "") {
      errobj.plan = "Select a Plan";
      valid = false;
    }
    if (
      [
        "Monthly",
        "Quarterly",
        "Half-Yearly",
        "Yearly",
      ].includes(plan)
    ) {
      errobj.plan = "Invalid Plan";
      valid = false;
    }

    if (program === "") {
      errobj.program = "Select a Program";
      valid = false;
    }
    if (
      [ "general", "personal_training", "session"].includes(
        
      )
    ) {
      errobj.program = "Invalid Program";
      valid = false;
    }
    if (!address) {
      errobj.address = "Address required";
      valid = false;
    }

    if (!dob) {
      errobj.dob = "Date of birth required";
      valid = false;
    }
    if (!amount_paid) {
      errobj.amountPaid = "Registration fee is requiered";
      valid = false;
    }

    setErrors(errobj);
    return valid;
  };

  const url = "https://fitness365degree-backend-aayc.vercel.app/registration";
  // function resetSlots() {
  //   setTimeData((prevState) => ({
  //     ...prevState,
  //     evening: prevState.evening.map((item) => ({
  //       ...item,
  //       isSelected: false,
  //     })),
  //     morning: prevState.morning.map((item) => ({
  //       ...item,
  //       isSelected: false,
  //     })),
  //   }));
  // }

  // function selectSlot(slotTime, index) {
  //   console.log(timeData);
  //   // resetSlots();
  //   // setTimeData((prevState) => ({
  //   //   ...prevState,
  //   //   [slotTime]: prevState[slotTime].map((item) => ({
  //   //     ...item,
  //   //     isSelected: item.id === index,
  //   //   })),
  //   //   selectedSlot: prevState[slotTime][index].time,
  //   // }));
  // }

  const handleRegistrationSubmit = async () => {
    if (!isValid()) {
      window.scrollTo({ top: 500, left: 0, behavior: "smooth" });
    } else {
      setLoading(true);
      try {
        if (!isValid()) {
          return loading(false);
        }
        // registrationData.timeSlot = timeData.selectedSlot;
        const { first_name, dob, mob_number, amount_paid, plan, program } =
          registrationData;
        const customId = generateCustomId(
          first_name,
          dob,
          mob_number,
          amount_paid,
          plan,
          program
        );
        registrationData.user_id = customId;
        console.log(customId);
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(registrationData),
        });

        const responseData = await response.json();

        console.log("Registered successfully:", responseData);
        setLoading(false);
        toast.success("🦄Registered successfully!", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // Clear the form data after submission
        setRegistrationData(initialRegistrationState);
      } catch (error) {
        console.error("Error submitting enquiry:", error);
        setLoading(false);
      }
    }
  };

  const handleRegistrationChange = (event) => {
    const { name, value } = event.target;

    setRegistrationData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {/* THIS IS PERSONAL DETAILS  */}

      <p className={Styles.label + Styles.title}>Personal Details</p>
      <HeaderLine />
      <div className={Styles.formBox}>
        <div className={Styles.box}>
          <label className={Styles.label}>First Name:</label>{" "}
          <input
            className={Styles.input}
            type="text"
            name="first_name"
            value={registrationData.first_name}
            onChange={handleRegistrationChange}
          />
          {errors.firstName && (
            <div>
              <p style={{ color: "#e35b52", fontSize: 12 }}>
                {errors.firstName}
              </p>
            </div>
          )}
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Last Name:</label>{" "}
          <input
            className={Styles.input}
            type="text"
            name="last_name"
            value={registrationData.last_name}
            onChange={handleRegistrationChange}
          />
          {errors.lastName && (
            <div>
              <p style={{ color: "#e35b52", fontSize: 12 }}>
                {errors.lastName}
              </p>
            </div>
          )}
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Gender:</label>{" "}
          <select
            className={Styles.select}
            name="gender"
            defaultValue={registrationData.gender}
            onChange={handleRegistrationChange}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Mobile Number:</label>{" "}
          <input
            className={Styles.input}
            type="text"
            name="mob_number"
            value={registrationData.mob_number}
            onChange={handleRegistrationChange}
          />
          {errors.mobileNumber && (
            <div>
              <p style={{ color: "#e35b52", fontSize: 12 }}>
                {errors.mobileNumber}
              </p>
            </div>
          )}
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Email Address:</label>{" "}
          <input
            className={Styles.input}
            type="email"
            name="email_address"
            value={registrationData.email_address}
            onChange={handleRegistrationChange}
          />
          {errors.email && (
            <div>
              <p style={{ color: "#e35b52", fontSize: 12 }}>{errors.email}</p>
            </div>
          )}
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Address:</label>{" "}
          <input
            className={Styles.input}
            type="text"
            name="address"
            value={registrationData.address}
            onChange={handleRegistrationChange}
          />
          {errors.address && (
            <div>
              <p style={{ color: "#e35b52", fontSize: 12 }}>{errors.address}</p>
            </div>
          )}
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Date of Birth:</label>{" "}
          <input
            className={Styles.input}
            type="date"
            name="dob"
            max="2023-05-06"
            value={registrationData.dob}
            onChange={handleRegistrationChange}
          />
          {errors.dob && (
            <div>
              <p style={{ color: "#e35b52", fontSize: 12 }}>{errors.dob}</p>
            </div>
          )}
        </div>
      </div>

      {/* THIS IS PROGRAM DETAILS  */}
      <p className={Styles.label + Styles.title}>Program Details</p>
      <HeaderLine />
      <div className={Styles.formBox}>
        <div className={Styles.box}>
          <label className={Styles.label}>Plan:</label>{" "}
          <select
            className={Styles.select}
            name="program"
            value={registrationData.program}
            onChange={handleRegistrationChange}
          >
            <option value="">Select a Program</option>
            <option value="general">General</option>
            <option value="personal_training">Personal Training</option>
            <option value="session">Session</option>
          </select>
          {errors.program && (
            <div>
              <p style={{ color: "#e35b52", fontSize: 12 }}>{errors.program}</p>
            </div>
          )}
        </div>
      </div>

      {/* THIS IS PLAN DETAILS  */}

      <p className={Styles.label + Styles.title}>Plan Details</p>
      <HeaderLine />
      <div className={Styles.formBox}>
        <div className={Styles.box}>
          <label className={Styles.label}>Plan:</label>{" "}
          <select
            className={Styles.select}
            name="plan"
            value={registrationData.plan}
            onChange={handleRegistrationChange}
          >
            <option value="">Select a Plan</option>
            <option value="monthly">Monthly</option>
            <option value="quarterly">Quarterly</option>
            <option value="half_yearly">Half-Yearly</option>
            <option value="yearly">Yearly</option>
          </select>
          {errors.plan && (
            <div>
              <p style={{ color: "#e35b52", fontSize: 12 }}>{errors.plan}</p>
            </div>
          )}
        </div>
      </div>

      {/* THIS IS PAYAMT DETAILS  */}

      <p className={Styles.label + Styles.title}>Payment Details</p>
      <HeaderLine />
      <div className={Styles.formBox}>
        <div className={Styles.box}>
          <label className={Styles.label}>Amount Paid:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="amount_paid"
            value={registrationData.amount_paid}
            onChange={handleRegistrationChange}
          />
          {errors.amountPaid && (
            <div>
              <p style={{ color: "#e35b52", fontSize: 12 }}>
                {errors.amountPaid}
              </p>
            </div>
          )}
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Amount Due:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="amount_due"
            value={registrationData.amount_due}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Amount Due Date:</label>{" "}
          <input
            className={Styles.input}
            type="date"
            name="amount_due_date"
            min="2023-05-07"
            value={registrationData.amount_due_date}
            onChange={handleRegistrationChange}
          />
        </div>
      </div>

      {/* THIS IS TIME SLOT  */}

      <p className={Styles.label + Styles.title}>Time Slot</p>
      <HeaderLine />
      <div>
        <div className="ml-5 text-white my-auto">Morning</div>
        <select
          className={Styles.timeSlot}
          name="timeSlot"
          defaultValue={registrationData.timeSlot}
          onChange={handleRegistrationChange}
        >
          <option value="06 to 07 AM">06 to 07 AM</option>
          <option value="07 to 08 AM">07 to 08 AM</option>
          <option value="08 to 09 AM">08 to 09 AM</option>
          <option value="09 to 10 AM">09 to 10 AM</option>
          <option value="10 to 11 AM">10 to 11 AM</option>
          <option value="11 to 12 AM">11 to 12 AM</option>
          <option value="12 to 01 PM">12 to 01 PM</option>
          <option value="01 to 02 PM">01 to 02 PM</option>
          <option value="02 to 03 PM">02 to 03 PM</option>
          <option value="03 to 04 PM">03 to 04 PM</option>
          <option value="04 to 05 PM">04 to 05 PM</option>
          <option value="05 to 06 PM">05 to 06 PM</option>
          <option value="06 to 07 PM">06 to 07 PM</option>
          <option value="07 to 08 PM">07 to 08 PM</option>
          <option value="08 to 09 PM">08 to 09 PM</option>
          <option value="09 to 10 PM">09 to 10 PM</option>
          <option value="10 to 11 PM">10 to 11 PM</option>
        </select>
        {/* <div className={Styles.formBoxTime}>
          {morning.map((val) => (
            <div
              key={val.id}
              className={` ml-5 w-28 text-white text-center rounded-lg cursor-pointer p-2
                  ${
                    timeData.selectedSlot === val.time
                      ? "bg-blue-600 hover:bg-gray-700"
                      : "bg-gray-600 hover:bg-gray-700"
                  })`}
              onClick={() => selectSlot("morning", val.id)}
            >
              {val.time}
            </div>
          ))}
        </div>
        <div className="ml-5 text-white my-auto">Evening</div>
        <div className={Styles.formBoxTime}>
          {evening.map((val) => (
            <div
              key={val.id}
              className={`col-span-1 ml-5 w-28 text-white text-center rounded-lg cursor-pointer p-2
                  ${
                    timeData.selectedSlot === val.time
                      ? "bg-blue-600 hover:bg-gray-700"
                      : "bg-gray-600 hover:bg-gray-700"
                  })`}
              onClick={() => selectSlot("evening", val.id)}
            >
              {val.time}
            </div>
          ))}
        </div> */}
      </div>

      {/* THIS IS MEASERMENTS   */}

      <p className={Styles.label + Styles.title}>Measurment</p>
      <HeaderLine />
      <div className={Styles.formBox}>
        <div className={Styles.box}>
          <label className={Styles.label}>Weight:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="weight"
            value={registrationData.weight}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Chest:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="chest"
            value={registrationData.chest}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Inhale:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="inhale"
            value={registrationData.inhale}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Exhale:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="exhale"
            value={registrationData.exhale}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Upper ABS:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="upper_abs"
            value={registrationData.upper_abs}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Lower ABS:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="lower_abs"
            value={registrationData.lower_abs}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Middle ABS:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="middle_abs"
            value={registrationData.middle_abs}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Waist:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="waist"
            value={registrationData.waist}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Hips:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="hips"
            value={registrationData.hips}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Upper Thigh:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="upper_thigh"
            value={registrationData.upper_thigh}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Lower Thigh:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="lower_thigh"
            value={registrationData.lower_thigh}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Calves:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="calves"
            value={registrationData.calves}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Neck:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="neck"
            value={registrationData.neck}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Shoulder:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="shoulder"
            value={registrationData.shoulder}
            onChange={handleRegistrationChange}
          />
        </div>
      </div>

      {/* THIS IS MEDICAL HISTORY  */}

      <p className={Styles.label + Styles.title}>Medical History</p>
      <HeaderLine />
      <div className={Styles.formBox}>
        <div className={Styles.box}>
          <label className={Styles.label}>Weight:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="bodyweight"
            value={registrationData.bodyweight}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Height:</label>{" "}
          <input
            className={Styles.input}
            type="number"
            name="height"
            value={registrationData.height}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Medical History:</label>{" "}
          <input
            className={Styles.input}
            type="text"
            name="medicalHistory"
            value={registrationData.medicalHistory}
            onChange={handleRegistrationChange}
          />
        </div>
        <div className={Styles.box}>
          <label className={Styles.label}>Food Preference:</label>{" "}
          <input
            className={Styles.input}
            type="text"
            name="foodPreference"
            value={registrationData.foodPreference}
            onChange={handleRegistrationChange}
          />
        </div>
      </div>

      {/* THIS IS SUBMIT BUTTON  */}

      <div className={Styles.btnBox}>
        {loading ? (
          <button
            disabled
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
          >
            <svg
              aria-hidden="true"
              role="status"
              className="inline w-4 h-4 mr-3 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
            Loading...
          </button>
        ) : (
          <button
            type="button"
            disabled={loading}
            onClick={handleRegistrationSubmit}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
          >
            Submit Registration
          </button>
        )}
      </div>
    </form>
  );
};

export default RegistrationForm;
